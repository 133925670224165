#root {
    height: 100%;
}

.main {
    height: 100%;
}

.mainContainer {
    padding-top: 70px;
    height: 100%;
}

body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto',sans-serif;
}

#form-container{
    background: white;
}


#form-container .card-body img{
    width: 100%;
    margin-bottom:20px;
    border-radius: 5px;
}

#form-container .company-info{
    -webkit-animation: tracking-in-expand-fwd 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand-fwd 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    text-align: left;
}

#company-name{
    color:#383838;
    font-family: 'Quicksand',sans-serif;
}


@-webkit-keyframes tracking-in-expand-fwd {
    0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px);
        transform: translateZ(-700px);
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}
@keyframes tracking-in-expand-fwd {
    0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px);
        transform: translateZ(-700px);
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.btn-violet{
    background-color: #7367f0 !important;
}
.btn-violet:hover{
    background-color: #5347d0 !important;
}

.btn-dark{
    background-color: #3c3c3c !important;
}
.btn-dark:hover{
    background-color: black !important;
}
.card-header h2#company-name {
    text-align: left;
    color: #2f2727;
    font-size: 2em;
}

.card-body{
    padding: 0;
}
.card-body h4{
    background: #f3f3f3;
    padding: 5px;
    color: black;
    font-family: 'Quicksand',sans-serif;
}
.card-body p{
    padding-left: 10px;
}

#form-container #bill{
    background: #3c3c3c;
}

#form-container #bill #details{
    text-align: center;
    vertical-align: middle;
    font-size: 1em;
    color:#f3f3f3;
    position: relative;
    top: 40%;
}
#form-container #bill #powered-by{
    text-align: center;
    color: #ffffff;
    position: relative;
    top: 85%;
    font-size: 0.8em;
}

#form-container #bill #powered-by a{
    text-decoration: none;
    color: white;
}

#form-container #bill #price{
    color:white;
    font-size: 1.8em;
    font-family: 'Quicksand',sans-serif;
}


#form-group-future-payments{
    border: 1px solid #ced4da;
    margin-top: 10px !important;
    text-align: justify;
    margin-bottom: 20px !important;
}


#form-group-future-payments .form-check {
    padding-bottom: 5px;
    padding-top: 5px;
}

div.react-multi-email{
    padding: 0.1em 0.8em !important;
}

div.react-multi-email div{
    font-size: 1em !important;;
}

.securelyBy{
    height: 35px;
    line-height: 35px;
    text-align: right;
    font-size: 0.8em;
}

.powerBy{
    height: 35px;
    text-align: right;
    font-size: 0.8em;
    line-height: 5em;
}

.securelyBy i{
    font-size: 3em;
    vertical-align: middle;

}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}


@media (min-width:1300px) {
    #form-container {
        width: 700px;
        margin: auto;
    }
}

.expiration {
    border: 1px solid #bbbbbb;
}
.expiration input {
    border: 0;
}

.react-multi-email .data-tag {
    line-height: 1;
    vertical-align: baseline;
    margin: 0.14285714em;
    background-color: #f3f3f3;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: 600;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    transition: background 0.1s ease;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}